import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faHammer } from '@fortawesome/free-solid-svg-icons';
import { faBitcoin } from '@fortawesome/free-brands-svg-icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Text,
  VStack,
  HStack,
  Box,
  useColorMode,
  Flex,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormControl,
  FormLabel,
  SimpleGrid,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
} from '@chakra-ui/react';
import GameInfoBanHammer from '../games/GameInfoBanHammer';

const GRID_SIZE = 60;

const Cell = React.memo(({ value, onClick, onMouseDown, onMouseEnter, isFirstOrange, showSatoshiTooltip }) => (
  <Tooltip
    label="Satoshi Nakamoto"
    isOpen={isFirstOrange && showSatoshiTooltip}
    placement="top"
    bg="#121212"
    color="white"
    fontSize="13px"
    padding="2px 4px"
    borderRadius="3px"
    border="1px solid white"
    hasArrow
  >
    <div
      style={{
        backgroundColor: value === 0 ? 'white' : value === 1 ? '#ffffff' : 'orange',
        border: '1px solid rgba(0,0,0,0.1)',
        cursor: 'crosshair'
      }}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
    />
  </Tooltip>
));

const FormControlWithTooltip = React.memo(({ label, id, value, onChange, min, max, tooltip }) => (
  <FormControl>
    <Flex align="center">
      <FormLabel htmlFor={id} mb={0}>{label}</FormLabel>
      <Popover trigger="hover" placement="top">
        <PopoverTrigger>
          <Box as="span" ml={1} cursor="help">
            <FontAwesomeIcon icon={faQuestionCircle} size="sm" />
          </Box>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody fontSize="12px" bg="#121212"
    color="white"
    padding="2px 4px"
    borderRadius="3px"
    border="1px solid white">{tooltip}</PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
    <NumberInput
      id={id}
      min={min}
      max={max}
      value={value}
      onChange={(valueString) => onChange(Number(valueString))}
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  </FormControl>
));

const BitcoinAdoptionSimulation = React.memo(({ isOpen, onClose }) => {
  const [grid, setGrid] = useState([]);
  const [isRunning, setIsRunning] = useState(false);
  const [generation, setGeneration] = useState(0);
  const [speed, setSpeed] = useState(1700);
  const [isComplete, setIsComplete] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [lastAffectedCell, setLastAffectedCell] = useState(null);
  const [showSatoshiTooltip, setShowSatoshiTooltip] = useState(true);
  const intervalRef = useRef(null);
  const resetTimerRef = useRef(null);

  const [revertChance, setRevertChance] = useState(20);
  const [maxReversions, setMaxReversions] = useState(2);
  const [conversionRate, setConversionRate] = useState(20);
  const [coreAdoptionRate, setCoreAdoptionRate] = useState(10);
  const [initialAdoption, setInitialAdoption] = useState(0);
  const [networkEffect, setNetworkEffect] = useState(0);
  const [economicCrisisChance, setEconomicCrisisChance] = useState(0);
  const [regulatoryAction, setRegulatoryAction] = useState(0);

  const initializeGrid = useCallback(() => {
    const rows = Array(GRID_SIZE).fill().map(() => Array(GRID_SIZE).fill({ value: 0, revertCount: 0 }));
    const center = Math.floor(GRID_SIZE / 2);
    rows[center][center] = { value: 2, revertCount: 0 };

    const totalCells = GRID_SIZE * GRID_SIZE;
    const cellsToAdopt = Math.floor((initialAdoption / 100) * totalCells);
    let adoptedCells = 1;

    while (adoptedCells < cellsToAdopt) {
      const x = Math.floor(Math.random() * GRID_SIZE);
      const y = Math.floor(Math.random() * GRID_SIZE);
      if (rows[x][y].value === 0) {
        rows[x][y] = { value: 1, revertCount: 0 };
        adoptedCells++;
      }
    }

    return rows;
  }, [initialAdoption]);

  const countBitcoinNeighbors = useCallback((grid, x, y) => {
    let count = { white: 0, colored: 0, core: 0 };
    for (let i = -1; i <= 1; i++) {
      for (let j = -1; j <= 1; j++) {
        if (i === 0 && j === 0) continue;
        const newX = x + i, newY = y + j;
        if (newX >= 0 && newX < GRID_SIZE && newY >= 0 && newY < GRID_SIZE) {
          if (grid[newX][newY].value === 0) {
            count.white++;
          } else if (grid[newX][newY].value === 1) {
            count.colored++;
          } else {
            count.core++;
          }
        }
      }
    }
    return count;
  }, []);

  const calculateDistanceFromCenter = (x, y) => {
    const center = Math.floor(GRID_SIZE / 2);
    return Math.sqrt(Math.pow(x - center, 2) + Math.pow(y - center, 2));
  };

  const nextGeneration = useCallback(() => {
    setShowSatoshiTooltip(false);
    setGrid(currentGrid => {
      const newGrid = currentGrid.map((row, i) =>
        row.map((cell, j) => {
          const { white: fiatNeighbors, colored: btcNeighbors, core: coreNeighbors } = countBitcoinNeighbors(currentGrid, i, j);
          const distanceFromCenter = calculateDistanceFromCenter(i, j);
          const maxDistance = Math.sqrt(2) * (GRID_SIZE / 2);
          const distanceFactor = 1 - (distanceFromCenter / maxDistance);

          const isEconomicCrisis = Math.random() < (economicCrisisChance / 100);
          const crisisMultiplier = isEconomicCrisis ? 2 : 1;
          const regulationMultiplier = Math.max(0, 1 - (regulatoryAction / 100));

          if (cell.value === 2) {
            if (fiatNeighbors > 0 && cell.revertCount < maxReversions) {
              const actualRevertChance = (revertChance / 100) * (1 - distanceFactor) * regulationMultiplier;
              if (Math.random() < actualRevertChance) {
                return { value: 0, revertCount: cell.revertCount + 1 };
              }
            }
            return { ...cell };
          }
          if (cell.value === 1) {
            if (fiatNeighbors > 0 && cell.revertCount < maxReversions) {
              const actualRevertChance = (revertChance / 100) * distanceFactor * regulationMultiplier;
              if (Math.random() < actualRevertChance) {
                return { value: 0, revertCount: cell.revertCount + 1 };
              }
            }
            return Math.random() < (coreAdoptionRate / 100) ? { value: 2, revertCount: 0 } : { ...cell };
          }
          if (btcNeighbors > 0 || coreNeighbors > 0) {
            let conversionChance = ((btcNeighbors + coreNeighbors * 2) * (conversionRate / 100)) + (generation * 0.0001);
            conversionChance *= (1 + ((btcNeighbors + coreNeighbors) * networkEffect) / 100);
            conversionChance *= crisisMultiplier * regulationMultiplier;

            if (Math.random() < conversionChance) {
              return Math.random() < (coreAdoptionRate / 100) ? { value: 2, revertCount: 0 } : { value: 1, revertCount: 0 };
            }
          }
          return { ...cell };
        })
      );

      if (newGrid.every(row => row.every(cell => cell.value === 2))) {
        clearInterval(intervalRef.current);
        setIsRunning(false);
        setIsComplete(true);
      }

      return newGrid;
    });
    setGeneration(gen => gen + 1);
  }, [countBitcoinNeighbors, generation, maxReversions, revertChance, conversionRate, coreAdoptionRate, networkEffect, economicCrisisChance, regulatoryAction]);

  const affectNeighbors = useCallback((i, j, newValue) => {
    setGrid(currentGrid => {
      const newGrid = [...currentGrid];
      const radius = 2;
      for (let x = Math.max(0, i - radius); x <= Math.min(GRID_SIZE - 1, i + radius); x++) {
        for (let y = Math.max(0, j - radius); y <= Math.min(GRID_SIZE - 1, j + radius); y++) {
          const distance = Math.sqrt(Math.pow(x - i, 2) + Math.pow(y - j, 2));
          if (distance <= radius) {
            newGrid[x] = [...newGrid[x]];
            newGrid[x][y] = { value: newValue, revertCount: 0 };
          }
        }
      }
      return newGrid;
    });
  }, []);

  const handleCellClick = useCallback((i, j) => {
    setGrid(currentGrid => {
      const newGrid = [...currentGrid];
      newGrid[i] = [...newGrid[i]];
      const newValue = (newGrid[i][j].value + 1) % 3;
      newGrid[i][j] = { value: newValue, revertCount: 0 };
      return newGrid;
    });
  }, []);

  const handleMouseDown = useCallback((i, j) => {
    setIsMouseDown(true);
    const newValue = (grid[i][j].value + 1) % 3;
    affectNeighbors(i, j, newValue);
    setLastAffectedCell({ i, j });
  }, [grid, affectNeighbors]);

  const handleMouseEnter = useCallback((i, j) => {
    if (isMouseDown && (lastAffectedCell.i !== i || lastAffectedCell.j !== j)) {
      const newValue = (grid[i][j].value + 1) % 3;
      affectNeighbors(i, j, newValue);
      setLastAffectedCell({ i, j });
    }
  }, [isMouseDown, lastAffectedCell, grid, affectNeighbors]);

  const handleMouseUp = useCallback(() => {
    setIsMouseDown(false);
    setLastAffectedCell(null);
  }, []);

  const restartSimulation = useCallback(() => {
    setGrid(initializeGrid());
    setGeneration(0);
    setIsRunning(false);
    setIsComplete(false);
    setShowSatoshiTooltip(true);
    clearInterval(intervalRef.current);
  }, [initializeGrid]);

  const bitcoinPercentage = useMemo(() => {
    return (grid.flat().filter(cell => cell.value > 0).length / (GRID_SIZE * GRID_SIZE) * 100).toFixed(2);
  }, [grid]);

  const gridCells = useMemo(() => {
    let firstOrangeFound = false;
    return grid.flat().map((cell, index) => {
      const i = Math.floor(index / GRID_SIZE);
      const j = index % GRID_SIZE;
      const isFirstOrange = !firstOrangeFound && cell.value === 2;
      if (isFirstOrange) {
        firstOrangeFound = true;
      }
      return (
        <Cell
          key={`${i}-${j}`}
          value={cell.value}
          onClick={() => handleCellClick(i, j)}
          onMouseDown={() => handleMouseDown(i, j)}
          onMouseEnter={() => handleMouseEnter(i, j)}
          isFirstOrange={isFirstOrange}
          showSatoshiTooltip={showSatoshiTooltip}
        />
      );
    });
  }, [grid, handleCellClick, handleMouseDown, handleMouseEnter, showSatoshiTooltip]);

  const { colorMode } = useColorMode();
  const bgColor = useMemo(() => colorMode === 'dark' ? '#121212' : '#ffffff', [colorMode]);
  const borderColor = useMemo(() => colorMode === 'dark' ? '#ffffff' : '#121212', [colorMode]);

  useEffect(() => {
    setGrid(initializeGrid());
  }, [initializeGrid]);

  useEffect(() => {
    if (isRunning) {
      setShowSatoshiTooltip(false);
      intervalRef.current = setInterval(nextGeneration, speed);
    }
    return () => clearInterval(intervalRef.current);
  }, [isRunning, speed, nextGeneration]);

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseUp]);

  useEffect(() => {
    if (!isOpen) {
      resetTimerRef.current = setTimeout(() => {
        restartSimulation();
      }, 120000);
    } else {
      if (resetTimerRef.current) {
        clearTimeout(resetTimerRef.current);
        resetTimerRef.current = null;
      }
    }

    return () => {
      if (resetTimerRef.current) {
        clearTimeout(resetTimerRef.current);
      }
    };
  }, [isOpen, restartSimulation]);

  const handleSpeedChange = useCallback((val) => setSpeed(2000 - val), []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={['full', 'full', 'full']} scrollBehavior="inside">
      <ModalOverlay backgroundColor="rgba(16, 22, 26, 0.9)" />
      <ModalContent
        bg={bgColor}
        borderWidth="2px"
        borderStyle="solid"
        borderColor={borderColor}
        borderRadius="6px"
        overflow="hidden"
        maxH={['100vh', '100vh', '100vh']}
        pb={5}
      >
        <ModalHeader>
          <Flex align="center" justify="flex-start">
            <FontAwesomeIcon icon={faBitcoin} style={{ marginRight: '10px' }} />
            <Text>BanHammer!</Text>
            <FontAwesomeIcon icon={faHammer} style={{ marginLeft: '10px' }} />
          </Flex>
          <Text fontSize="sm" fontWeight="normal" color="gray">
            Hit the orange-pilled cells to "ban" Bitcoin
          </Text>
        </ModalHeader>
        <GameInfoBanHammer />
        <ModalCloseButton
          _hover={{
            bg: 'gray.500',
          }}
          _focus={{
            boxShadow: '0 0 0 1px rgba(66, 153, 225, 0.6)',
          }}
        />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Box borderWidth="2px" borderRadius="lg" p={4} borderColor={'gray.500'} bg="black" width="100%" height={['300px', '400px', '600px']}>
              <div style={{ width: '100%', height: '100%', display: 'grid', gridTemplateColumns: `repeat(${GRID_SIZE}, 1fr)`, position: 'relative' }}>
                {gridCells}
                {isComplete && (
                  <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)'
                  }}>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                      width="350px" height="350px" viewBox="100 100 400 400" xmlSpace="preserve">
                      <filter id="glow" height="300%" width="300%" x="-75%" y="-75%">
                        <feGaussianBlur stdDeviation="10" result="coloredBlur"/>
                        <feMerge>
                          <feMergeNode in="coloredBlur"/>
                          <feMergeNode in="SourceGraphic"/>
                        </feMerge>
                      </filter>
                      <path className="path" style={{ fill: 'orange', filter: 'url(#glow)' }} d="M446.089,261.45c6.135-41.001-25.084-63.033-67.769-77.735l13.844-55.532l-33.801-8.424l-13.48,54.068
                        c-8.896-2.217-18.015-4.304-27.091-6.371l13.568-54.429l-33.776-8.424l-13.861,55.521c-7.354-1.676-14.575-3.328-21.587-5.073
                        l0.034-0.171l-46.617-11.64l-8.993,36.102c0,0,25.08,5.746,24.549,6.105c13.689,3.42,16.159,12.478,15.75,19.658L208.93,357.23
                        c-1.675,4.158-5.925,10.401-15.494,8.031c0.338,0.485-24.579-6.134-24.579-6.134l-9.631,40.468l36.843,9.188
                        c8.178,2.051,16.209,4.19,24.098,6.217l-13.978,56.17l33.764,8.424l13.852-55.571c9.235,2.499,18.186,4.813,26.948,6.995
                        l-13.802,55.309l33.801,8.424l13.994-56.061c57.648,10.902,100.998,6.502,119.237-45.627c14.705-41.979-0.731-66.193-31.06-81.984
                        C425.008,305.984,441.655,291.455,446.089,261.45z M368.859,369.754c-10.455,41.983-81.128,19.285-104.052,13.589l18.562-74.404
                        C306.28,314.65,379.774,325.975,368.859,369.754z M379.302,260.846c-9.527,38.187-68.358,18.781-87.442,14.023l16.828-67.489
                        C327.767,212.14,389.234,221.02,379.302,260.846z"/>
                    </svg>
                  </div>
                )}
              </div>
            </Box>

            <Flex direction={['column', 'column', 'row']} justify="space-between" align={['stretch', 'stretch', 'center']} wrap="wrap">
              <HStack spacing={2} mb={[2, 2, 0]}>
                <Button
                  borderRadius={3}
                  onClick={() => setIsRunning(!isRunning)}
                  colorScheme="orange"
                  size="sm"
                  isDisabled={isComplete}
                >
                  {isRunning ? 'Stop' : 'Start'}
                </Button>
                <Button
                  borderRadius={3}
                  onClick={nextGeneration}
                  isDisabled={isRunning || isComplete}
                  colorScheme="orange"
                  size="xs"
                >
                  Next Gen
                </Button>
                <Button
                  borderRadius={3}
                  onClick={restartSimulation}
                  colorScheme="red"
                  size="xs"
                >
                  Restart
                </Button>
              </HStack>

              <Flex align="center" mb={[2, 2, 0]} flex={1} maxW={['100%', '100%', '300px']} mx={[0, 0, 4]}>
                <Text fontSize="sm" mr={2}>Speed:</Text>
                <Slider
                  min={100}
                  max={2000}
                  value={2000 - speed}
                  onChange={handleSpeedChange}
                  flex={1}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </Flex>

              <Text fontSize="sm">
                Generation: {generation} | Adoption: {bitcoinPercentage}%
              </Text>
            </Flex>

            <SimpleGrid columns={{ base: 1, md: 4 }} spacing={4}>
              <FormControlWithTooltip
                label="Adoption Rate (%)"
                id="conversion-rate"
                value={conversionRate}
                onChange={setConversionRate}
                min={0}
                max={100}
                tooltip="The percentage chance of a 'fiat user' converting to Bitcoin when exposed to adopters."
              />
              <FormControlWithTooltip
                label="Revert Chance (%)"
                id="revert-chance"
                value={revertChance}
                onChange={setRevertChance}
                min={0}
                max={100}
                tooltip="The likelihood of an adopter dumping their Bitcoin and reverting back to 'fiat user' status."
              />
              <FormControlWithTooltip
                label="Max Reversions"
                id="max-reversions"
                value={maxReversions}
                onChange={setMaxReversions}
                min={0}
                max={100}
                tooltip="The maximum number of times an adopter can revert before becoming a permanent adopter."
              />
              <FormControlWithTooltip
                label="Hardcore Adoption Rate (%)"
                id="core-adoption-rate"
                value={coreAdoptionRate}
                onChange={setCoreAdoptionRate}
                min={0}
                max={100}
                tooltip="The chance of a regular adopter becoming a 'hardcore adopter' who won't ever revert."
              />
              <FormControlWithTooltip
                label="Initial Adoption (%)"
                id="initial-adoption"
                value={initialAdoption}
                onChange={setInitialAdoption}
                min={0}
                max={100}
                tooltip="The percentage of cells that start as Bitcoin adopters at the beginning of the simulation."
              />
              <FormControlWithTooltip
                label="Network Effect Boost (%)"
                id="network-effect"
                value={networkEffect}
                onChange={setNetworkEffect}
                min={0}
                max={100}
                tooltip="Additional conversion boost based on the number of nearby adopters, simulating strong network effects."
              />
              <FormControlWithTooltip
                label="Economic Crisis Chance (%)"
                id="economic-crisis"
                value={economicCrisisChance}
                onChange={setEconomicCrisisChance}
                min={0}
                max={100}
                tooltip="Probability of an economic crisis occurring, which temporarily boosts Bitcoin adoption."
              />
              <FormControlWithTooltip
                label="Regulatory Action (%)"
                id="regulatory-action"
                value={regulatoryAction}
                onChange={setRegulatoryAction}
                min={0}
                max={100}
                tooltip="Simulates government interference, potentially slowing down Bitcoin adoption."
              />
            </SimpleGrid>

            {/* <Text fontSize="xs" color="gray.500" textAlign="justify">
              <strong>How to Play</strong> Click or drag to flip cells. Orange represents Bitcoin adoption, white represents fiat currency users. Each orange cell has a chance of reverting to white if adjacent to one, with the probability decreasing towards the center. Cells can revert a limited number of times before becoming permanently orange-pilled.
            </Text>
            <Text fontSize="xs" color="gray.500" textAlign="justify">
              <strong>Options</strong> Conversion Rate affects how quickly adoption spreads, while Hardcore Adoption Rate determines how often regular adopters become hardcore adopters. Adjust the Revert Chance to control how likely cells are to flip back to fiat. Set Max Reversions to limit how many times a cell can flip. Initial Adoption sets the starting percentage of adopted cells. Network Effect Boost increases conversion rates based on neighboring adopters. Economic Crisis Chance introduces random events that boost adoption, while Regulatory Action simulates government interference that can slow adoption.
            </Text> */}
            <Text fontSize="xs" color="gray.500" textAlign="justify">
              <strong>Disclaimer</strong> This simulation is an educational tool and does not predict real-world adoption rates. Actual adoption involves numerous complex factors not represented here.
            </Text>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}, (prevProps, nextProps) => {
  // Custom comparison function for React.memo
  return prevProps.isOpen === nextProps.isOpen;
});

export default BitcoinAdoptionSimulation;
