import { Flex, Link, useColorMode, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { faCalculator, faSun, faMoon, faCalendarAlt, faChartLine, faBell, faGamepad, faBook } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { ThemedNavIcon } from '../../../theme/ThemedNavIcon';
import { TradingViewModal } from '../../data-display/TradingViewModal';
import { NotificationModal } from '../../data-display/NotificationModal';
import { BitcoinCalculatorAdvanced } from '../../data-display/BitcoinCalculatorAdvanced';
import BitcoinPriceGame from '../../games/BitcoinPriceGame';
import BitcoinAdoptionSimulation from '../../data-display/BitcoinAdoptionSimulation';
import { useState, useEffect } from 'react';
import BtcLexiconModal from '../../data-display/BtcLexicon';

export const AppNavIcons = ({
  data,
  bitcoinPrice,
  glossaryData,
  lastMempoolBlockData,
  formatNumber
}) => {
  const { colorMode, toggleColorMode, setColorMode } = useColorMode();
  const [isTradingViewModalOpen, setIsTradingViewModalOpen] = useState(false);
  const [isBitcoinCalculatorAdvancedOpen, setIsBitcoinCalculatorAdvancedOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [isGlossaryOpen, setIsGlossaryOpen] = useState(false);
  const [isBitcoinPriceGameOpen, setIsBitcoinPriceGameOpen] = useState(false);
  const [isAdoptionSimulationOpen, setIsAdoptionSimulationOpen] = useState(false);
  const ENABLE_NOTIFICATION = false;

  const handleOpenTradingViewModal = () => setIsTradingViewModalOpen(true);
  const handleCloseTradingViewModal = () => setIsTradingViewModalOpen(false);
  const handleOpenBitcoinCalculatorAdvanced = () => setIsBitcoinCalculatorAdvancedOpen(true);
  const handleCloseBitcoinCalculatorAdvanced = () => setIsBitcoinCalculatorAdvancedOpen(false);
  const handleOpenNotificationModal = () => setIsNotificationModalOpen(true);
  const handleCloseNotificationModal = () => setIsNotificationModalOpen(false);
  const handleOpenGlossary = () => setIsGlossaryOpen(true);
  const handleCloseGlossary = () => setIsGlossaryOpen(false);
  const handleOpenBitcoinPriceGame = () => setIsBitcoinPriceGameOpen(true);
  const handleCloseBitcoinPriceGame = () => setIsBitcoinPriceGameOpen(false);
  const handleOpenAdoptionSimulation = () => setIsAdoptionSimulationOpen(true);
  const handleCloseAdoptionSimulation = () => setIsAdoptionSimulationOpen(false);

  const saveColorModeInLocalStorage = (mode) => {
    localStorage.setItem('colorMode', mode);
  }

  const toggleAndSaveColorMode = () => {
    toggleColorMode();
    saveColorModeInLocalStorage(colorMode === 'dark' ? 'light' : 'dark');
  }

  useEffect(() => {
    const storedColorMode = localStorage.getItem('colorMode');
    if (storedColorMode) {
      setColorMode(storedColorMode);
    } else {
      setColorMode('dark');
    }
  }, [setColorMode]);

  return (
    <Flex gap={{ base: 4, md: 6 }}>
      {ENABLE_NOTIFICATION && (
        <Link onClick={handleOpenNotificationModal}>
          <ThemedNavIcon icon={faBell} fontSize={{ base: "18px", md: "20px" }} color="orange" />
        </Link>
      )}

      <Link onClick={handleOpenTradingViewModal}>
        <ThemedNavIcon icon={faChartLine} fontSize={{ base: "18px", md: "20px" }} />
      </Link>

      <Link onClick={handleOpenBitcoinCalculatorAdvanced}>
        <ThemedNavIcon icon={faCalculator} fontSize={{ base: "18px", md: "20px" }} />
      </Link>

      <Link onClick={handleOpenGlossary}>
        <ThemedNavIcon icon={faBook} fontSize={{ base: "18px", md: "20px" }} />
      </Link>

       <Menu>
        <MenuButton as={Link}>
          <ThemedNavIcon icon={faGamepad} fontSize={{ base: "18px", md: "20px" }} />
        </MenuButton>
        <MenuList
          bg="#121212"
          border="1px solid white"
          borderRadius="3px"
          p={0}  // Remove default padding
        >
          <MenuItem
            onClick={handleOpenBitcoinPriceGame}
            color="white"
            bg="#121212"
            _hover={{ bg: "#777777" }}
            _focus={{ bg: "#777777" }}
            _active={{ bg: "#777777" }}
            transition="background-color 0.2s"
          >
            "Get Rekt" Simulator
          </MenuItem>
          <MenuItem
            onClick={handleOpenAdoptionSimulation}
            color="white"
            bg="#121212"
            _hover={{ bg: "#777777" }}
            _focus={{ bg: "#777777" }}
            _active={{ bg: "#777777" }}
            transition="background-color 0.2s"
          >
            BanHammer!
          </MenuItem>
        </MenuList>
      </Menu>

      <Link href='https://timechaincalendar.com' target="_blank" display={{ base: "none", sm: "inline" }}>
        <ThemedNavIcon icon={faCalendarAlt} fontSize={{ base: "18px", md: "20px" }} />
      </Link>

      <Link href="https://twitter.com/TimechainStats" target="_blank" display={{ base: "none", sm: "inline" }}>
        <ThemedNavIcon icon={faTwitter} fontSize={{ base: "18px", md: "20px" }} />
      </Link>

      <Link onClick={toggleAndSaveColorMode}>
        <ThemedNavIcon
          icon={colorMode === 'dark' ? faSun : faMoon}
          fontSize={{ base: "18px", md: "20px" }}
        />
      </Link>

      <TradingViewModal isOpen={isTradingViewModalOpen} onClose={handleCloseTradingViewModal} />

      <BitcoinCalculatorAdvanced
        isOpen={isBitcoinCalculatorAdvancedOpen}
        onClose={handleCloseBitcoinCalculatorAdvanced}
        bitcoinPrice={bitcoinPrice}
        data={data}
        formatNumber={formatNumber}
      />

      <BtcLexiconModal
        isOpen={isGlossaryOpen}
        onClose={handleCloseGlossary}
        glossaryData={glossaryData}
      />

      <NotificationModal isOpen={isNotificationModalOpen} onClose={handleCloseNotificationModal} />

      <BitcoinPriceGame
        isOpen={isBitcoinPriceGameOpen}
        onClose={handleCloseBitcoinPriceGame}
        bitcoinPrice={bitcoinPrice}
        lastMempoolBlockData={lastMempoolBlockData}
      />

      <BitcoinAdoptionSimulation
        isOpen={isAdoptionSimulationOpen}
        onClose={handleCloseAdoptionSimulation}
      />
    </Flex>
  );
};