import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Text,
  VStack,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  FormControl,
  FormLabel,
  useColorMode,
  Flex,
  RadioGroup,
  Radio,
  Box,
  keyframes,
  useToast,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBitcoinSign, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { faBitcoin } from '@fortawesome/free-brands-svg-icons';
import AchievementBadge1k from './AchievementBadge1k';
import AchievementBadge10k from './AchievementBadge10k';
import AchievementBadge100k from './AchievementBadge100k';
import AchievementBadge1M from './AchievementBadge1M';
import AchievementBadge21M from './AchievementBadge21M';
import AchievementBadgeWinner from './AchievementBadgeWinner';
import AchievementBadgeRekt from './AchievementBadgeRekt';
import GameInfo from './GameInfo';


const gameConfig = {
  initialCredits: 100,
  topUpAmount: 20,
  maxCredits: 100,
  topUpIntervals: [10, 7, 5, 2, 1].map(min => min * 60 * 1000),
  initialDelay: 20 * 1000
};

const fallingCoins = keyframes`
  0% { transform: translateY(-100vh) rotate(0deg); opacity: 1; }
  100% { transform: translateY(100vh) rotate(720deg); opacity: 0; }
`;

const shakeAnimation = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(10px); }
  50% { transform: translateX(-10px); }
  75% { transform: translateX(10px); }
  100% { transform: translateX(0); }
`;

const calculateMultiplier = (duration, predictionType, isSpecificPrice = false, isCustomTime = false) => {
  if (isSpecificPrice && isCustomTime) return 100;
  if (isSpecificPrice) return 21;

  if (predictionType === 'price') {
    if (duration > 300) return 3;
    if (duration > 180) return 2.5;
    if (duration > 60) return 2;
    if (duration > 30) return 1.5;
    return 1;
  } else {
    if (duration <= 60) return 3;
    if (duration <= 120) return 2.5;
    if (duration <= 300) return 2;
    if (duration <= 600) return 1.5;
    return 1;
  }
};

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  if (hours > 0) {
    return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  } else {
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  }
};

const BitcoinPriceGame = ({ isOpen, onClose, bitcoinPrice, lastMempoolBlockData }) => {
  const [showAchievementBadge1k, setShowAchievementBadge1k] = useState(false);
  const [showAchievementBadge10k, setShowAchievementBadge10k] = useState(false);
  const [showAchievementBadge100k, setShowAchievementBadge100k] = useState(false);
  const [showAchievementBadge1M, setShowAchievementBadge1M] = useState(false);
  const [showAchievementBadge21M, setShowAchievementBadge21M] = useState(false);
  const [showAchievementBadgeRekt, setShowAchievementBadgeRekt] = useState(false);
  const [showAchievementBadgeWinner, setShowAchievementBadgeWinner] = useState(false);
  const [hasWonBefore, setHasWonBefore] = useState(() => {
    return localStorage.getItem('bitcoinGameHasWonBefore') === 'true';
  });
  const [hasReached1kBefore, setHasReached1kBefore] = useState(() => {
    return localStorage.getItem('bitcoinGameHasReached1kBefore') === 'true';
  });
  const [hasReached10kBefore, setHasReached10kBefore] = useState(() => {
    return localStorage.getItem('bitcoinGameHasReached10kBefore') === 'true';
  });
  const [hasReached100kBefore, setHasReached100kBefore] = useState(() => {
    return localStorage.getItem('bitcoinGameHasReached100kBefore') === 'true';
  });
  const [hasReached1MBefore, setHasReached1MBefore] = useState(() => {
    return localStorage.getItem('bitcoinGameHasReached1MBefore') === 'true';
  });
  const [hasReached21MBefore, setHasReached21MBefore] = useState(() => {
    return localStorage.getItem('bitcoinGameHasReached21MBefore') === 'true';
  });

  const [credits, setCredits] = useState(() => {
    const storedCredits = localStorage.getItem('bitcoinGameCredits');
    return storedCredits ? parseInt(storedCredits) : gameConfig.initialCredits;
  });
  const [betAmount, setBetAmount] = useState(1);
  const [prediction, setPrediction] = useState('');
  const [predictionType, setPredictionType] = useState('price');
  const [targetPrice, setTargetPrice] = useState('');
  const [timeFrame, setTimeFrame] = useState('10');
  const [customTime, setCustomTime] = useState('00:00:00');
  const [gameStatus, setGameStatus] = useState('idle');
  const [blockTimePrediction, setBlockTimePrediction] = useState('');
  const [customBlockTime, setCustomBlockTime] = useState('00:00:00');
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [activeBets, setActiveBets] = useState(() => {
    const storedBets = localStorage.getItem('bitcoinGameActiveBets');
    return storedBets ? JSON.parse(storedBets) : [];
  });
  const [message, setMessage] = useState('');
  const [nextTopUp, setNextTopUp] = useState(null);
  const topUpTimerRef = useRef(null);
  const countdownTimerRef = useRef(null);
  const messageTimeoutRef = useRef(null);
  const [topUpStage, setTopUpStage] = useState(0);
  const [showWinAnimation, setShowWinAnimation] = useState(false);
  const [showLossAnimation, setShowLossAnimation] = useState(false);
  const latestPriceRef = useRef(bitcoinPrice);
  const latestBlockDataRef = useRef(lastMempoolBlockData);
  const [activeBetTerms, setActiveBetTerms] = useState('');
  const [countdownTime, setCountdownTime] = useState(null);
  const [betEndTime, setBetEndTime] = useState(null);
  const [isCountingUp, setIsCountingUp] = useState(false);
  const modalSize = useBreakpointValue({ base: "xl", md: "3xl" });

  useEffect(() => {
    latestPriceRef.current = bitcoinPrice;
  }, [bitcoinPrice]);

  useEffect(() => {
    latestBlockDataRef.current = lastMempoolBlockData;
  }, [lastMempoolBlockData]);

  useEffect(() => {
    localStorage.setItem('bitcoinGameCredits', credits.toString());
  }, [credits]);

  useEffect(() => {
    localStorage.setItem('bitcoinGameActiveBets', JSON.stringify(activeBets));
  }, [activeBets]);

  useEffect(() => {
    if (prediction !== 'specific') {
      setTargetPrice('');
    }
  }, [prediction]);

  useEffect(() => {
    if (timeFrame !== 'custom') {
      setCustomTime('00:00:00');
    }
  }, [timeFrame]);

  useEffect(() => {
    if (blockTimePrediction !== 'custom') {
      setCustomBlockTime('00:00:00');
    }
  }, [blockTimePrediction]);

  const getCurrentPrice = useCallback(() => {
    return typeof latestPriceRef.current === 'number' ? latestPriceRef.current : parseFloat(latestPriceRef.current);
  }, []);

  const formatBitcoinPrice = useCallback((price) => {
    if (typeof price === 'number') {
      return price.toFixed(2);
    } else if (typeof price === 'string') {
      const numPrice = parseFloat(price);
      return isNaN(numPrice) ? 'Invalid' : numPrice.toFixed(2);
    } else {
      return 'Unknown';
    }
  }, []);

  const checkResult = useCallback((predictionData) => {
    if (predictionData.resultChecked) return;
  
    const currentTime = new Date().getTime();
    const timePassed = (currentTime - predictionData.startTime) / 60000;
    const endPrice = getCurrentPrice();
    const startPrice = predictionData.startPrice;
    const priceDifference = endPrice - startPrice;
    let won = false;
    let shouldEndBet = false;
    let blocksMined = 0;
  
    console.log('Checking result:', {
      currentTime,
      betEndTime,
      timePassed,
      startPrice,
      endPrice,
      priceDifference,
      prediction: predictionData.value
    });
  
    if (predictionData.type === 'price') {
      if (currentTime >= betEndTime) {
        shouldEndBet = true;
        if (predictionData.value === 'up' && priceDifference > 0) won = true;
        if (predictionData.value === 'down' && priceDifference < 0) won = true;
        if (predictionData.value === 'specific' && Math.abs(endPrice - parseFloat(predictionData.targetPrice)) <= 10) won = true;
      }
    } else if (predictionData.type === 'blockTime') {
      const currentBlockHeight = latestBlockDataRef.current ? latestBlockDataRef.current.height : predictionData.startBlockHeight;
      blocksMined = currentBlockHeight - predictionData.startBlockHeight;
      const predictedTime = predictionData.value === 'custom' ? parseFloat(predictionData.customBlockTime) : parseFloat(predictionData.value);
  
      console.log('Block time prediction check:', {
        currentBlockHeight,
        startBlockHeight: predictionData.startBlockHeight,
        blocksMined,
        timePassed,
        predictedTime,
        predictionValue: predictionData.value
      });
  
      if (predictionData.value === 'more') {
        shouldEndBet = true;
        won = timePassed >= 10 && blocksMined === 0;
      } else {
        if (currentTime >= betEndTime) {
          shouldEndBet = true;
          won = blocksMined >= 1;
        }
      }
    }
  
    if (shouldEndBet) {
      predictionData.resultChecked = true;
      if (won) {
        if (!hasWonBefore) {
          setShowAchievementBadgeWinner(true);
          setHasWonBefore(true);
        }
        const payout = predictionData.betAmount * predictionData.multiplier * 2;
        setCredits(prevCredits => {
          const newCredits = prevCredits + payout;
          if (newCredits >= 1000 && prevCredits < 1000 && !hasReached1kBefore) {
            setShowAchievementBadge1k(true);
            setHasReached1kBefore(true);
          }
          if (newCredits >= 10000 && prevCredits < 10000 && !hasReached10kBefore) {
            setShowAchievementBadge10k(true);
            setHasReached10kBefore(true);
          }
          if (newCredits >= 100000 && prevCredits < 100000 && !hasReached100kBefore) {
            setShowAchievementBadge100k(true);
            setHasReached100kBefore(true);
          }
          if (newCredits >= 1000000 && prevCredits < 1000000 && !hasReached1MBefore) {
            setShowAchievementBadge1M(true);
            setHasReached1MBefore(true);
          }
          if (newCredits >= 21000000 && prevCredits < 21000000 && !hasReached21MBefore) {
            setShowAchievementBadge21M(true);
            setHasReached21MBefore(true);
          }
          return newCredits;
        });
        setMessage(
          <Text color="green.500">
            WINNER!<br />
            {predictionData.type === 'price' ?
              `Price went from $${startPrice.toFixed(2)} to $${endPrice.toFixed(2)} (${priceDifference > 0 ? '+' : ''}${priceDifference.toFixed(2)}).` :
              `${blocksMined > 0 ? 'A new block was mined' : 'No new block was mined'} within the predicted time.`}
            <br />Payout: {payout.toFixed(0)} credits ({predictionData.multiplier}x multiplier)
          </Text>
        );
        setShowWinAnimation(true);
        setTimeout(() => setShowWinAnimation(false), 3000);
        toast({
          duration: 4000,
          position: "top",
          variant: "solid",
          render: () => (
            <Box color='white' p={3} bg='green.500' borderRadius={3}>
              Winner! Payout: {payout.toFixed(0)} credits ({predictionData.multiplier}x multiplier)
            </Box>
          ),
        });
      } else {
        // Check if player is rekt after losing the bet
        if (credits === 0) {
          setShowAchievementBadgeRekt(true);
        }
        setMessage(
          <Text color="red.500">
            You lost {predictionData.betAmount} credits!<br />
            {predictionData.type === 'price' ?
              `Price went from $${startPrice.toFixed(2)} to $${endPrice.toFixed(2)} (${priceDifference > 0 ? '+' : ''}$${priceDifference.toFixed(2)}).` :
              `${blocksMined > 0 ? 'A new block was mined' : 'No new block was mined'} within the predicted time.`}
          </Text>
        );
        setShowLossAnimation(true);
        setTimeout(() => setShowLossAnimation(false), 500);
        toast({
          duration: 4000,
          position: "top",
          variant: "solid",
          render: () => (
            <Box color='white' p={3} bg='red.500' borderRadius={3}>
              You lost {predictionData.betAmount} credits! Better luck next time!
            </Box>
          ),
        });
      }
  
      setActiveBets([]);
      setGameStatus('idle');
      setActiveBetTerms('');
      setCountdownTime(null);
      setBetEndTime(null);
  
      if (messageTimeoutRef.current) {
        clearTimeout(messageTimeoutRef.current);
      }
      messageTimeoutRef.current = setTimeout(() => {
        setMessage('');
      }, 20000);
    }
  }, [credits, getCurrentPrice, latestBlockDataRef, betEndTime, toast, hasWonBefore, hasReached1kBefore, hasReached10kBefore, hasReached100kBefore, hasReached1MBefore, hasReached21MBefore]);
  
  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      activeBets.forEach(bet => {
        if (bet.type === 'blockTime') {
          if (bet.isMoreThan10Minutes) {
            const elapsedTime = (currentTime - bet.startTime) / 1000;
            if (elapsedTime >= 600 && latestBlockDataRef.current.height === bet.startBlockHeight) {
              checkResult({ ...bet, value: 'more' });
            } else if (elapsedTime < 600 && latestBlockDataRef.current.height > bet.startBlockHeight) {
              checkResult({ ...bet, value: 'less' });
            }
          } else {
            const predictedTime = bet.value === 'custom' ? parseFloat(bet.customBlockTime) : parseFloat(bet.value);
            if (predictedTime <= 10 || currentTime >= bet.endTime) {
              checkResult(bet);
            }
          }
        } else if (currentTime >= bet.endTime) {
          checkResult(bet);
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [activeBets, checkResult, latestBlockDataRef]);

  const formatPredictionTime = useCallback((time) => {
    if (time === 'custom') return customTime;
    const seconds = parseInt(time);
    if (seconds < 60) return `${seconds} seconds`;
    if (seconds < 3600) return `${Math.floor(seconds / 60)} minute${seconds >= 120 ? 's' : ''}`;
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours} hour${hours > 1 ? 's' : ''}${minutes > 0 ? ` ${minutes} minute${minutes > 1 ? 's' : ''}` : ''}`;
  }, [customTime]);

  const formatBlockTimePrediction = useCallback((prediction) => {
    if (prediction === 'custom') return customBlockTime;
    if (prediction === 'more') return 'more than 10 minutes';
    return `less than ${prediction} minute${prediction !== '1' ? 's' : ''}`;
  }, [customBlockTime]);

  const handleBet = useCallback(() => {
    if (credits < betAmount) {
      setMessage(<Text color="red.500">Not enough credits</Text>);
      return;
    }
  
    if (activeBets.length >= 1) {
      setMessage(<Text color="red.500">You can only have up to 1 active prediction at a time</Text>);
      return;
    }
  
    setGameStatus('playing');
    setCredits(prevCredits => prevCredits - betAmount); // Deduct bet amount immediately
  
    const startPrice = getCurrentPrice();
    let betTerms = '';
    let duration = 0;
    let multiplier = 1;
    let isCustomTime = false;
  
    if (predictionType === 'price') {
      if (timeFrame === 'custom') {
        const [hours, minutes, seconds] = customTime.split(':').map(Number);
        duration = hours * 3600 + minutes * 60 + seconds;
        isCustomTime = true;
      } else {
        duration = parseInt(timeFrame);
      }
      multiplier = calculateMultiplier(duration, 'price', prediction === 'specific', isCustomTime);
      const predictionTime = formatPredictionTime(timeFrame);
      betTerms = `${betAmount} credits on ${prediction === 'specific' ? `price reaching ${targetPrice}` : `price going ${prediction}`} within ${predictionTime}\nStarting price: $${startPrice.toFixed(2)} \nPotential winnings: ${(betAmount * multiplier * 2).toFixed(0)} credits`;
      setIsCountingUp(false);
    } else {
      const predictionTime = formatBlockTimePrediction(blockTimePrediction);
      if (blockTimePrediction === 'custom') {
        const [hours, minutes, seconds] = customBlockTime.split(':').map(Number);
        duration = hours * 3600 + minutes * 60 + seconds;
        isCustomTime = true;
      } else if (blockTimePrediction === 'more') {
        duration = 15 * 60; // Set to 15 minutes, but we'll start counting from 0
      } else {
        duration = parseInt(blockTimePrediction) * 60;
      }
      multiplier = calculateMultiplier(duration, 'blockTime', false, isCustomTime);
      betTerms = `${betAmount} credits on next block being mined ${predictionTime} \nPotential winnings: ${(betAmount * multiplier * 2).toFixed(0)} credits`;
      setIsCountingUp(blockTimePrediction === 'more');
    }
  
    const endTime = new Date().getTime() + duration * 1000;
  
    const newBet = {
      id: Date.now(),
      type: predictionType,
      value: predictionType === 'price' ? prediction : blockTimePrediction,
      targetPrice: targetPrice,
      customTime: customTime,
      customBlockTime: customBlockTime,
      betAmount: betAmount,
      startPrice: startPrice,
      startTime: new Date().getTime(),
      startBlockHeight: latestBlockDataRef.current ? latestBlockDataRef.current.height : 0,
      endTime: endTime,
      betTerms: betTerms,
      duration: duration,
      multiplier: multiplier,
      isMoreThan10Minutes: predictionType === 'blockTime' && blockTimePrediction === 'more',
    };
  
    setActiveBets([newBet]);
    setBetEndTime(endTime);
    setCountdownTime(newBet.isMoreThan10Minutes ? 0 : duration);
    setActiveBetTerms(betTerms);
  
    setMessage(
      <>
        <Text color={'gray'}>Good Luck!</Text>
      </>
    );
  }, [credits, betAmount, activeBets.length, getCurrentPrice, timeFrame, predictionType, prediction, blockTimePrediction, targetPrice, customTime, customBlockTime, latestBlockDataRef, formatPredictionTime, formatBlockTimePrediction]);

  useEffect(() => {
    const topUpCredits = () => {
      setCredits(prevCredits => {
        const newCredits = Math.min(prevCredits + gameConfig.topUpAmount, gameConfig.maxCredits);
        console.log('Topping up credits:', prevCredits, '->', newCredits);
        return newCredits;
      });
      setTopUpStage(prevStage => prevStage + 1);
    };

    const setupTopUpTimer = () => {
      if (credits < gameConfig.maxCredits && !topUpTimerRef.current) {
        console.log('Setting up initial delay timer for', gameConfig.initialDelay, 'ms');
        topUpTimerRef.current = setTimeout(() => {
          console.log('Initial delay complete, starting top-up cycle');
          topUpCredits();
          setNextTopUp(Date.now() + gameConfig.topUpIntervals[0]);

          const cycleTopUp = () => {
            if (topUpStage < gameConfig.topUpIntervals.length - 1) {
              topUpTimerRef.current = setTimeout(() => {
                topUpCredits();
                setNextTopUp(Date.now() + gameConfig.topUpIntervals[topUpStage + 1]);
                cycleTopUp();
              }, gameConfig.topUpIntervals[topUpStage]);
            }
          };

          cycleTopUp();
        }, gameConfig.initialDelay);

        setNextTopUp(Date.now() + gameConfig.initialDelay);
      } else if (credits >= gameConfig.maxCredits && topUpTimerRef.current) {
        console.log('Clearing existing timer as credits are maxed');
        clearTimeout(topUpTimerRef.current);
        topUpTimerRef.current = null;
        setNextTopUp(null);
        setTopUpStage(0);
      }
    };

    setupTopUpTimer();

    return () => {
      if (topUpTimerRef.current) {
        console.log('Clearing top-up timer');
        clearTimeout(topUpTimerRef.current);
        topUpTimerRef.current = null;
      }
    };
  }, [credits, topUpStage]);

  useEffect(() => {
    const updateCountdown = () => {
      if (nextTopUp) {
        const now = Date.now();
        if (now >= nextTopUp) {
          if (topUpStage < gameConfig.topUpIntervals.length - 1) {
            setNextTopUp(now + gameConfig.topUpIntervals[topUpStage + 1]);
          } else {
            setNextTopUp(null);
          }
        }
      }
    };

    if (nextTopUp && !countdownTimerRef.current) {
      countdownTimerRef.current = setInterval(updateCountdown, 1000);
    }

    return () => {
      if (countdownTimerRef.current) {
        clearInterval(countdownTimerRef.current);
        countdownTimerRef.current = null;
      }
    };
  }, [nextTopUp, topUpStage]);

  useEffect(() => {
    const blockCheckInterval = setInterval(() => {
      if (latestBlockDataRef.current) {
        activeBets.forEach(bet => {
          if (bet.type === 'blockTime' && latestBlockDataRef.current.height > bet.startBlockHeight) {
            checkResult(bet);
          }
        });
      }
    }, 1000); // Check every second

    return () => clearInterval(blockCheckInterval);
  }, [checkResult, latestBlockDataRef, activeBets]);

  useEffect(() => {
    let timer;
    if (countdownTime !== null) {
      timer = setInterval(() => {
        setCountdownTime(prevTime => {
          if (isCountingUp) {
            return prevTime + 1;
          } else if (prevTime <= 1) {
            clearInterval(timer);
            return 0;
          } else {
            return prevTime - 1;
          }
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdownTime, isCountingUp]);

  const bgColor = colorMode === 'dark' ? '#121212' : '#ffffff';
  const borderColor = colorMode === 'dark' ? '#ffffff' : '#121212';

  if (!latestBlockDataRef.current) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay backgroundColor="rgba(16, 22, 26, 0.9)" />
        <ModalContent
          bg={bgColor}
          pb={6}
          borderWidth="2px"
          borderStyle="solid"
          borderColor={borderColor}
          borderRadius="6px"
          overflow="hidden"
        >
          <ModalHeader>Loading...</ModalHeader>
          <ModalBody>
            <Text>Please wait while we fetch the latest block data.</Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
      <ModalOverlay backgroundColor="rgba(16, 22, 26, 0.9)" />
      <ModalContent
        bg={bgColor}
        pb={6}
        borderWidth="2px"
        borderStyle="solid"
        borderColor={borderColor}
        borderRadius="6px"
        overflow="hidden"
        position="relative"
        animation={showLossAnimation ? `${shakeAnimation} 0.5s` : 'none'}
      >
        {showWinAnimation && (
          <>
            {[...Array(20)].map((_, i) => (
              <Box
                key={i}
                position="absolute"
                top="-20px"
                left={`${Math.random() * 100}%`}
                animation={`${fallingCoins} ${Math.random() * 2 + 1}s linear infinite`}
                color="gold"
                fontSize="20px"
              >
                <FontAwesomeIcon icon={faBitcoin} />
              </Box>
            ))}
          </>
        )}
        <ModalHeader>
          <Flex align="center" justify="flex-start">
            <FontAwesomeIcon icon={faGamepad} style={{ marginRight: '10px' }} />
            <Text>"Get Rekt" Simulator</Text>
          </Flex>
          <Text fontSize="sm" fontWeight="normal" color="gray">
            Step right up and try your luck!
          </Text>
        </ModalHeader>
        <GameInfo />
        <ModalCloseButton
          _hover={{
            bg: 'gray.500',
          }}
          _focus={{
            boxShadow: '0 0 0 1px rgba(66, 153, 225, 0.6)',
          }}
        />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel>{predictionType === 'blockTime' ? 'Current Block Height' : 'Current Bitcoin Price'}</FormLabel>
              <InputGroup>
                <InputLeftAddon>
                  {predictionType === 'blockTime' ? '#' : <FontAwesomeIcon icon={faBitcoinSign} />}
                </InputLeftAddon>
                <Input
                  value={predictionType === 'blockTime' ? latestBlockDataRef.current.height : formatBitcoinPrice(bitcoinPrice)}
                  isReadOnly
                  borderWidth="1.5px"
                  fontSize={'16px'}
                />
              </InputGroup>
            </FormControl>

            <HStack spacing={4}>
              <FormControl flex={1}>
                <FormLabel>Your Credits</FormLabel>
                <Input value={credits} isReadOnly borderWidth="1.5px" fontSize={'16px'} />
              </FormControl>

              <FormControl flex={1}>
                <FormLabel>Bet Amount</FormLabel>
                <Input
                  type="number"
                  value={betAmount}
                  onChange={(e) => setBetAmount(Math.max(1, parseInt(e.target.value)))}
                  borderWidth="1.5px"
                  fontSize={'16px'}
                />
              </FormControl>
            </HStack>

            {credits < gameConfig.maxCredits && nextTopUp && (
              <Box>
                <Text fontSize={'small'}>
                  {gameConfig.topUpAmount} new credits in {Math.max(0, Math.ceil((nextTopUp - Date.now()) / 1000))} seconds
                </Text>
              </Box>
            )}

            <FormControl>
              <FormLabel>Prediction Type</FormLabel>
              <RadioGroup onChange={setPredictionType} value={predictionType}>
                <HStack spacing={4}>
                  <Radio value="price">Price</Radio>
                  <Radio value="blockTime">Block Time</Radio>
                </HStack>
              </RadioGroup>
            </FormControl>

            {predictionType === 'price' && (
              <>
                <HStack spacing={4} align="flex-start">
                  <FormControl flex={1}>
                    <FormLabel>Your Prediction</FormLabel>
                    <Select
                      value={prediction}
                      onChange={(e) => {
                        setPrediction(e.target.value);
                        if (e.target.value !== 'specific') {
                          setTargetPrice('');
                        }
                      }}
                      borderWidth="1.5px"
                      fontSize={'16px'}
                    >
                      <option value="">Select prediction</option>
                      <option value="up">Price will go up</option>
                      <option value="down">Price will go down</option>
                      <option value="specific">Specific price</option>
                    </Select>
                  </FormControl>

                  <FormControl flex={1}>
                    <FormLabel>Time Frame</FormLabel>
                    <Select
                      value={timeFrame}
                      onChange={(e) => {
                        setTimeFrame(e.target.value);
                        if (e.target.value !== 'custom') {
                          setCustomTime('00:00:00');
                        }
                      }}
                      borderWidth="1.5px"
                      fontSize={'16px'}
                    >
                      <option value="10">10 seconds</option>
                      <option value="30">30 seconds</option>
                      <option value="60">1 minute</option>
                      <option value="180">3 minutes</option>
                      <option value="300">5 minutes</option>
                      <option value="custom">Custom time</option>
                    </Select>
                  </FormControl>
                </HStack>

                {prediction === 'specific' && (
                  <FormControl>
                    <FormLabel>Target Price</FormLabel>
                    <InputGroup>
                      <InputLeftAddon><FontAwesomeIcon icon={faBitcoinSign} /></InputLeftAddon>
                      <Input
                        type="number"
                        value={targetPrice}
                        onChange={(e) => setTargetPrice(e.target.value)}
                        placeholder="Enter target price"
                        borderWidth="1.5px"
                        fontSize={'16px'}
                      />
                    </InputGroup>
                  </FormControl>
                )}

                {timeFrame === 'custom' && (
                  <FormControl>
                    <FormLabel>Custom Time (HH:MM:SS)</FormLabel>
                    <Input
                      type="text"
                      value={customTime}
                      onChange={(e) => setCustomTime(e.target.value)}
                      placeholder="Enter custom time (HH:MM:SS)"
                      borderWidth="1.5px"
                      fontSize={'16px'}
                    />
                  </FormControl>
                )}
              </>
            )}

            {predictionType === 'blockTime' && (
              <FormControl>
                <FormLabel>Block Time Prediction</FormLabel>
                <Select
                  value={blockTimePrediction}
                  onChange={(e) => {
                    setBlockTimePrediction(e.target.value);
                    if (e.target.value !== 'custom') {
                      setCustomBlockTime('00:00:00');
                    }
                  }}
                  borderWidth="1.5px"
                  fontSize={'16px'}
                >
                  <option value="">Select prediction</option>
                  <option value="1">Less than 1 minute</option>
                  <option value="2">Less than 2 minutes</option>
                  <option value="5">Less than 5 minutes</option>
                  <option value="10">Less than 10 minutes</option>
                  <option value="more">More than 10 minutes</option>
                  <option value="custom">Custom time</option>
                </Select>
              </FormControl>
            )}

            {predictionType === 'blockTime' && blockTimePrediction === 'custom' && (
              <FormControl>
                <FormLabel>Custom Block Time (HH:MM:SS)</FormLabel>
                <Input
                  type="text"
                  value={customBlockTime}
                  onChange={(e) => setCustomBlockTime(e.target.value)}
                  placeholder="Enter custom block time (HH:MM:SS)"
                  borderWidth="1.5px"
                  fontSize={'16px'}
                />
              </FormControl>
            )}

            <Button
              onClick={handleBet}
              isDisabled={gameStatus !== 'idle' || (predictionType === 'price' ? !prediction : !blockTimePrediction) || activeBets.length >= 1}
              bg="orange.500"
              color="white"
              _hover={{ bg: "orange.600" }}
              size="md"
            >
              Make Prediction
            </Button>

            {activeBetTerms && (
              <Box borderWidth="2px" borderRadius="lg" p={4} borderColor={'gray.500'} bg="black" color="white">
                <Flex mt={0} justifyContent="space-between" alignItems="flex-start">
                  <Box flex="3">
                    <Text fontWeight="bold">Your Prediction</Text>
                    <Text fontSize="sm">{activeBetTerms.split('\n')[0]}</Text>

                    <Text mt={2} fontWeight="bold">Terms</Text>
                    <Text fontSize="small">{activeBetTerms.split('\n')[2]}</Text>
                    <Text fontSize="small">{activeBetTerms.split('\n')[1]}</Text>
                    {countdownTime !== null && (
                      <Text mt={2} fontSize={'sm'}>
                        {isCountingUp ? 'Time elapsed: ' : 'Time remaining: '}
                        {formatTime(countdownTime)}
                      </Text>
                    )}
                  </Box>
                  {message && (
                    <Box flex="1" ml={4}>
                      <Text fontSize="md" fontWeight="bold" textAlign="right">
                        {message}
                      </Text>
                    </Box>
                  )}
                </Flex>
              </Box>
            )}
            {message && (
              <Box flex="1" ml={4}>
                <Text fontSize="md" fontWeight="bold" textAlign="right">
                  {message}
                </Text>
              </Box>
            )}

{showAchievementBadge1k && (
            <AchievementBadge1k onClose={() => setShowAchievementBadge1k(false)} />
          )}

          {showAchievementBadge10k && (
            <AchievementBadge10k onClose={() => setShowAchievementBadge10k(false)} />
          )}

          {showAchievementBadge100k && (
            <AchievementBadge100k onClose={() => setShowAchievementBadge100k(false)} />
          )}

          {showAchievementBadge1M && (
            <AchievementBadge1M onClose={() => setShowAchievementBadge1M(false)} />
          )}

          {showAchievementBadge21M && (
            <AchievementBadge21M onClose={() => setShowAchievementBadge21M(false)} />
          )}

          {showAchievementBadgeWinner && (
            <AchievementBadgeWinner onClose={() => setShowAchievementBadgeWinner(false)} />
          )}
          
          {showAchievementBadgeRekt && (
            <AchievementBadgeRekt onClose={() => setShowAchievementBadgeRekt(false)} />
          )}



            <Text fontSize="sm" color="gray.500">
              You can make up to 1 prediction at a time. Active: {activeBets.length}
            </Text>

            <Text fontSize="xs" color="gray.500" textAlign="justify">
              <strong>Disclaimer:</strong> The Get Rekt Simulator game is for educational purposes only and does not involve real money. Attempting to time the Bitcoin market or engaging in short-term trading can be extremely risky! Always do your own research, and never risk more than you can afford to lose. Consider seeking help if you have a gambling problem.
            </Text>

          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BitcoinPriceGame;