// src/components/layout/header/AppHeader.jsx
import { Flex, useColorMode, useBreakpointValue, Text, HStack } from '@chakra-ui/react'
import { Link } from '@chakra-ui/react';
import { AppLogo } from './AppLogo'
import { AppNavIcons } from './AppNavIcons'
import { useState, useEffect, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { ThemedTextHeader } from '../../../theme/ThemeTextHeader';
import { differenceInSeconds, differenceInMinutes, differenceInHours, differenceInDays } from "date-fns";

export const AppHeader = ({
  data,
  bitcoinPrice,
  glossaryData,
  lastMempoolBlockData,
  formatNumber
}) => {
  const { colorMode } = useColorMode();
  const bgColor = colorMode === 'dark' ? 'primary.700' : 'blue.500';
  const borderBottomColor = colorMode === 'dark' ? 'gray.500' : 'blue.300';
  const headerIconColor = colorMode === 'dark' ? '#777' : '#fff';
  const isSmallish2Screen = useBreakpointValue({ base: true, xl: false });
  const isSmallishScreen = useBreakpointValue({ base: true, lg: false });
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [showNewBlock, setShowNewBlock] = useState(false);
  const prevBlockHeightRef = useRef(null);
  const [timeSinceBlock, setTimeSinceBlock] = useState('');
  const blockTimestampRef = useRef(null);

  const randomElements = [
    (
      <ThemedTextHeader>
        Thanks for your support
        <Link
          href="https://www.youtube.com/channel/UCVfMv5xEfrafk1rSthJ0t9g"
          target='_blank'
          mx="1"
          textDecoration={'underline'}
        >
          Pleb Underground
        </Link>
        <FontAwesomeIcon
          icon={faYoutube}
          size={'lg'}
          color={headerIconColor}
        />
      </ThemedTextHeader>
    ),
    (
      <ThemedTextHeader>
        Create your own dashboard! Mix-and-match the stats at the top.
      </ThemedTextHeader>
    ),
  ];

  const [showElement, setShowElement] = useState(() => Math.floor(Math.random() * randomElements.length));

  const getTimeSinceBlock = useCallback((blockTimestamp) => {
    const now = new Date();
    const blockDate = new Date(blockTimestamp * 1000);
    const secondsDiff = differenceInSeconds(now, blockDate);
    const minutesDiff = differenceInMinutes(now, blockDate);
    const hoursDiff = differenceInHours(now, blockDate);
    const daysDiff = differenceInDays(now, blockDate);

    if (daysDiff > 0) {
      return `${daysDiff}d ${hoursDiff % 24}h ago`;
    } else if (hoursDiff > 0) {
      return `${hoursDiff}h ${minutesDiff % 60}m ago`;
    } else if (minutesDiff > 0) {
      return `${minutesDiff}m ago`;
    } else {
      return `${secondsDiff}s ago`;
    }
  }, []);

  useEffect(() => {
    const updateRandomElement = () => {
      setShowElement(Math.floor(Math.random() * randomElements.length));
    };

    const intervalId = setInterval(updateRandomElement, 300000); // Update every 5 minutes

    return () => clearInterval(intervalId);
  }, [randomElements.length]);

  useEffect(() => {
    setIsInitialRender(false);
  }, []);

  useEffect(() => {
    let timer;
    if (lastMempoolBlockData) {
      blockTimestampRef.current = lastMempoolBlockData.timestamp;
      
      const updateTimeSinceBlock = () => {
        setTimeSinceBlock(getTimeSinceBlock(blockTimestampRef.current));
      };

      updateTimeSinceBlock(); // Initial update

      // Determine update frequency based on time since block
      const determineUpdateFrequency = () => {
        const timeSinceBlockInSeconds = differenceInSeconds(new Date(), new Date(blockTimestampRef.current * 1000));
        if (timeSinceBlockInSeconds < 300) { // Less than 5 minutes
          return 5000; // Update every 5 seconds
        } else if (timeSinceBlockInSeconds < 3600) { // Less than 1 hour
          return 30000; // Update every 30 seconds
        } else {
          return 60000; // Update every minute
        }
      };

      const setupTimer = () => {
        if (timer) clearInterval(timer);
        timer = setInterval(() => {
          updateTimeSinceBlock();
          setupTimer(); // Recalculate interval after each update
        }, determineUpdateFrequency());
      };

      setupTimer();

      if (!prevBlockHeightRef.current || prevBlockHeightRef.current !== lastMempoolBlockData.height) {
        setShowNewBlock(true);
        prevBlockHeightRef.current = lastMempoolBlockData.height;

        setTimeout(() => {
          setShowNewBlock(false);
        }, 20000); // Show new block notification for 20 seconds
      }
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [lastMempoolBlockData, getTimeSinceBlock]);

  const newBlockElement = (
    <HStack spacing={2}>
      <Text color={colorMode === 'dark' ? 'orange.500' : 'white'} fontWeight="bold">
        Block {formatNumber(lastMempoolBlockData?.height, 0)} Found by {lastMempoolBlockData?.extras?.pool?.name || 'Unknown Pool'} ~{timeSinceBlock}
      </Text>
    </HStack>
  );

  return (
    <Flex
      bg={bgColor}
      h="58px"
      borderBottomWidth="1px"
      borderBottomColor={borderBottomColor}
      py="5px"
      px="20px"
      align="center"
      justify="space-between"
      position="sticky"
      top="0"
      zIndex="1000"
      style={{
        backgroundColor: isInitialRender ? '#121212' : undefined,
        borderBottomColor: isInitialRender ? '#777' : undefined
      }}
    >
      <AppLogo />

      {!isSmallishScreen && !isSmallish2Screen && (
        showNewBlock ? newBlockElement : randomElements[showElement]
      )}

      <AppNavIcons
        bitcoinPrice={bitcoinPrice}
        data={data}
        glossaryData={glossaryData}
        lastMempoolBlockData={lastMempoolBlockData}
        formatNumber={formatNumber}
      />
    </Flex>
  )
}



// // src/components/layout/header/AppHeader.jsx - 0.0.1
// import { Flex, useColorMode, useBreakpointValue } from '@chakra-ui/react'
// import { Link } from '@chakra-ui/react';
// import { AppLogo } from './AppLogo'
// import { AppNavIcons } from './AppNavIcons'
// import { useState, useEffect } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faYoutube } from '@fortawesome/free-brands-svg-icons';
// // import { faVoteYea } from '@fortawesome/free-solid-svg-icons';
// import { ThemedTextHeader } from '../../../theme/ThemeTextHeader';
// // import { DonationsModal } from '../../data-display/DonationsModal';
// // import { ThemedDonateButtonSm } from '../../../theme/ThemedDonateButtonSm';
// // import { faBookOpen } from '@fortawesome/free-solid-svg-icons';

// export const AppHeader = ({
//   data,
//   bitcoinPrice,
//   glossaryData,
//   lastMempoolBlockData,
//   formatNumber
// }) => {
//   const { colorMode } = useColorMode();
//   const bgColor = colorMode === 'dark' ? 'primary.700' : 'blue.500';
//   const borderBottomColor = colorMode === 'dark' ? 'gray.500' : 'blue.300';
//   const headerIconColor = colorMode === 'dark' ? '#777' : '#fff';
//   const isSmallish2Screen = useBreakpointValue({ base: true, xl: false });
//   const isSmallishScreen = useBreakpointValue({ base: true, lg: false });
//   const [isInitialRender, setIsInitialRender] = useState(true);
//   // const [bitcoinPrice, setBitcoinPrice] = useState(0);
//   // const [isDonationsModalOpen, setDonationsModalOpen] = useState(false);

//   // const openDonationsModal = () => setDonationsModalOpen(true);
//   // const closeDonationsModal = () => setDonationsModalOpen(false);
//   // const donateForm = () => {
//   //   closeDonationsModal();
//   // };


//   // const calculateTimeLeft = () => {
//   //   const now = new Date();
//   //   const targetTime = new Date(now.getTime() + (1 * 60 * 60 * 1000)); // Add 1 hours in milliseconds
//   //   const difference = targetTime - now;
  
//   //   let timeLeft = {};
  
//   //   if (difference > 0) {
//   //     timeLeft = {
//   //       hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
//   //       minutes: Math.floor((difference / 1000 / 60) % 60),
//   //       seconds: Math.floor((difference / 1000) % 60),
//   //     };
//   //   }
  
//   //   return timeLeft;
//   // };

//   // const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

//   // useEffect(() => {
//   //   setTimeout(() => {
//   //     setTimeLeft(calculateTimeLeft());
//   //   }, 1000);
//   // });

//   // const timerComponents = [];

//   // Object.keys(timeLeft).forEach((interval) => {
//   //   if (!timeLeft[interval]) {
//   //     return;
//   //   }

//   //   timerComponents.push(
//   //     <span>
//   //       {timeLeft[interval]} {interval}{" "}
//   //     </span>
//   //   );
//   // });


//   const randomElements = [

//     (
//       <ThemedTextHeader>
//         Thanks for your support
//         <Link
//           href="https://www.youtube.com/channel/UCVfMv5xEfrafk1rSthJ0t9g"
//           target='_blank'
//           mx="1"
//           textDecoration={'underline'}
//         >
//           Pleb Underground
//         </Link>
//         <FontAwesomeIcon
//           icon={faYoutube}
//           size={'lg'}
//           color={headerIconColor}
//         />
//       </ThemedTextHeader>
//     ),

//     (
//       <ThemedTextHeader>
//         Create your own dashboard! Mix-and-match the stats at the top.
//         {/* <FontAwesomeIcon
//           icon={faInfoCircle}
//           size={'lg'}
//           color={headerIconColor}
//         /> */}
//       </ThemedTextHeader>
//     ),

//     // (
//     //   <ThemedTextHeader>
//     //     Thanks for your support
//     //     <Link
//     //       href="https://twitter.com/S0cratesz"
//     //       target='_blank'
//     //       mx="1"
//     //       textDecoration={'underline'}
//     //     >
//     //       @S0cratesz

//     //     </Link>
//     //     <FontAwesomeIcon
//     //       icon={faTwitter}
//     //       size={'lg'}
//     //       color={headerIconColor}
//     //     />
//     //   </ThemedTextHeader>
//     // ),

//     // (
//     //   <ThemedTextHeader>
//     //     Help keep TimechainStats running!
//     //     <ThemedDonateButtonSm onClick={openDonationsModal}><DonationsModal isOpen={isDonationsModalOpen} onClose={closeDonationsModal} donateForm={donateForm} />
//     //       <FontAwesomeIcon icon={faBtc} style={{ marginRight: (isSmallishScreen || isSmallish2Screen) ? '0' : '0.5rem' }} size={'lg'} />Donate</ThemedDonateButtonSm>
//     //   </ThemedTextHeader>
//     // ),

//     // (
//     //   <ThemedTextHeader>
//     //     Learn about Bitcoin!
//     //     <Link
//     //       href="https://btclexicon.com"
//     //       target='_blank'
//     //       mx="1"
//     //       textDecoration={'underline'}
//     //     >
//     //       BTCLexicon.com
//     //     </Link>
//     //     <FontAwesomeIcon
//     //       icon={faBookOpen}
//     //       size={'lg'}
//     //       color={headerIconColor}
//     //     />
//     //   </ThemedTextHeader>
//     // ),

//     // (
//     //   <ThemedTextHeader>
//     //     Only 1 hour left to vote for TimechainStats! -
//     //     <Link
//     //       href="https://twitter.com/w_s_bitcoin/status/1778508512496075155"
//     //       target='_blank'
//     //       mx="1"
//     //       textDecoration={'underline'}
//     //       title="Click here to vote for TimechainStats on the Twitter poll"
//     //     >
//     //       VOTE NOW
//     //     </Link>
//     //     <FontAwesomeIcon
//     //       icon={faVoteYea}
//     //       size={'lg'}
//     //       color={headerIconColor}
//     //     />
//     //   </ThemedTextHeader>
//     // ),

//   ];

//   const [showElement, setShowElement] = useState(() => Math.floor(Math.random() * randomElements.length));

//   useEffect(() => {
//     const updateRandomElement = () => {
//       setShowElement(Math.floor(Math.random() * randomElements.length));
//     };

//     const intervalId = setInterval(updateRandomElement, 300000); // Update every 5 minutes

//     return () => clearInterval(intervalId);
//   }, [randomElements.length]);

//   useEffect(() => {
//     setIsInitialRender(false);
//   }, []);


//   return (
//     <Flex
//       bg={bgColor}
//       h="58px"
//       borderBottomWidth="1px"
//       borderBottomColor={borderBottomColor}
//       py="5px"
//       px="20px"
//       align="center"
//       justify="space-between"
//       position="sticky"
//       top="0"
//       zIndex="1000"
//       style={{
//         backgroundColor: isInitialRender ? '#121212' : undefined,
//         borderBottomColor: isInitialRender ? '#777' : undefined
//       }}
//     >
//       <AppLogo />

//       {!isSmallishScreen && !isSmallish2Screen && (
//         randomElements[showElement]
//       )}

//       <AppNavIcons
//       bitcoinPrice={bitcoinPrice}
//       data={data}
//       glossaryData={glossaryData}
//       lastMempoolBlockData={lastMempoolBlockData}
//       formatNumber={formatNumber}
//       />
//     </Flex>
//   )
// }