import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import ApexCharts from 'apexcharts';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex';
import { Text, Select, Box, useColorMode } from '@chakra-ui/react';
import { CustomSpinner } from './CustomSpinner';
import { recentBlocksState } from '../../recoilState';
import { useRecoilValue } from 'recoil';

const useDeepCompareMemoize = (value) => {
  const ref = useRef();
  if (JSON.stringify(value) !== JSON.stringify(ref.current)) {
    ref.current = value;
  }
  return ref.current;
};

export const RewardsToFeesTile = ({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
  uniqueChartId,
  formatLargeNumber,
}) => {
  const [chartData, setChartData] = useState([]);
  const recentBlocks = useRecoilValue(recentBlocksState);
  const [selectedBlock, setSelectedBlock] = useState(null);

  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const { colorMode } = useColorMode();
  const borderColor = colorMode === 'dark' ? '#121212' : '#ffffff';

  const handleSelectedBlockChange = useCallback((event) => {
    const newSelectedBlock = event.target.value === '24hr' 
      ? '24hr' 
      : recentBlocks.find((x) => String(x.height) === event.target.value);
    setSelectedBlock(newSelectedBlock);
  }, [recentBlocks]);

  const satsToBTC = useCallback((sats) => (sats / 100000000).toFixed(8), []);

  const memoizedRecentBlocks = useDeepCompareMemoize(recentBlocks);
  const memoizedData = useDeepCompareMemoize(data);

  useEffect(() => {
    if (memoizedRecentBlocks.length > 0 && !selectedBlock) {
      const latestBlock = memoizedRecentBlocks[memoizedRecentBlocks.length - 1];
      setSelectedBlock(latestBlock);
    }
  }, [memoizedRecentBlocks, selectedBlock]);

  const processChartData = useCallback(() => {
    if (memoizedData && memoizedData.ts11 && selectedBlock) {
      let formattedData;

      if (selectedBlock === '24hr') {
        const rewards144 = memoizedData.ts11.rewards144;
        const totalSubsidy = parseInt(rewards144.totalReward) - parseInt(rewards144.totalFee);
        formattedData = [
          {
            name: 'Total Subsidy (BTC)',
            value: parseFloat(satsToBTC(totalSubsidy)),
          },
          {
            name: 'Total Fee (BTC)',
            value: parseFloat(satsToBTC(rewards144.totalFee)),
          },
        ];
      } else {
        const actualReward = parseInt(selectedBlock.extras.reward) - parseInt(selectedBlock.extras.totalFees);
        formattedData = [
          {
            name: 'Total Subsidy (BTC)',
            value: parseFloat(satsToBTC(actualReward)),
          },
          {
            name: 'Total Fees (BTC)',
            value: parseFloat(satsToBTC(selectedBlock.extras.totalFees)),
          },
        ];
      }
      return formattedData;
    }
    return [];
  }, [memoizedData, selectedBlock, satsToBTC]);

  const chartOptions = useMemo(() => {
    return {
      chart: {
        fontFamily: 'Ubuntu, sans-serif',
        type: 'donut',
        height: '100%',
        width: '100%',
        toolbar: {
          offsetY: 0,
          tools: {
            download: false,
          },
          padding: {
            bottom: 0,
          },
          color: '#78909C',
        },
      },
      labels: chartData.map(data => data.name),
      series: chartData.map(data => data.value),
      legend: {
        position: 'top',
        labels: {
          colors: colorMode === 'dark' ? '#999' : '#333',
        },
      },
      colors: ["#f7931a", "#329239", "#0d579b", "#4d4d4d"],
      dataLabels: {
        dropShadow: {
          enabled: false
        },
        style: {
          colors: [colorMode === 'dark' ? '#fff' : '#000'],
        },
      },
      stroke: {
        colors: [borderColor],
      },
      tooltip: {
        theme: colorMode,
        y: {
          formatter: (value) => {
            return `${value.toFixed(8)} BTC`;
          },
        },
      },
    };
  }, [chartData, borderColor, colorMode]);

  useEffect(() => {
    const newChartData = processChartData();
    setChartData(newChartData);
  }, [processChartData]);

  useEffect(() => {
    if (chartData.length > 0 && chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.updateOptions({
          ...chartOptions,
          labels: chartData.map(data => data.name),
          series: chartData.map(data => data.value),
        });
      } else {
        chartInstance.current = new ApexCharts(chartRef.current, chartOptions);
        chartInstance.current.render();
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
        chartInstance.current = null;
      }
    };
  }, [chartData, chartOptions]);

  if (!memoizedData || !memoizedData.ts11) {
    return <CustomSpinner />;
  }

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      style={{ minHeight: '337px', maxHeight: '100%' }}
    >
      <DataTableHeader title={title} subtitle={subtitle} icon={icon} />

      <Select
        onChange={handleSelectedBlockChange}
        variant="filled"
        size="xs"
        alignSelf="flex-start"
        width="150px"
        mt={2}
        mb={2}
        value={selectedBlock === '24hr' ? '24hr' : selectedBlock?.height}
      >
        {memoizedRecentBlocks &&
          memoizedRecentBlocks
          .slice(-7)
          .reverse()
            .map((block) => (
              <option key={block.height} value={block.height}>
                {`Block: ${block.height.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
              </option>
            ))}
        <option value="24hr">144 Blocks</option>
      </Select>

      <Box flexGrow={1} minHeight='337px' pt={2}>
        <div ref={chartRef} className={`chart-container-${uniqueChartId}`} style={{ height: '100%' }} />
      </Box>

      {selectedBlock !== '24hr' && selectedBlock && (
        <>
          <Text textAlign={'center'} fontSize={'12px'} mt={-4} mb={1} fontWeight={'bold'}>
            {`Fees ${satsToBTC(selectedBlock.extras.totalFees)} BTC`} &nbsp;•&nbsp; Subsidy 3.125 BTC/block
          </Text>
          <Text textAlign={'center'} fontSize={'12px'} mt={0} mb={2} fontWeight={'bold'}>
            {`Reward ${satsToBTC(selectedBlock.extras.reward)} BTC`} &nbsp;•&nbsp; {selectedBlock.extras.pool.name}
          </Text>
        </>
      )}
      {selectedBlock === '24hr' && memoizedData.ts11.rewards144 && (
        <Text textAlign={'center'} fontSize={'12px'} mt={-4} mb={2} fontWeight={'bold'}>
          {`Total Fees ${satsToBTC(memoizedData.ts11.rewards144.totalFee)} BTC`} &nbsp;•&nbsp; {`Total Reward ${satsToBTC(memoizedData.ts11.rewards144.totalReward)} BTC`}
        </Text>
      )}
      <DataTableFooter link={link} linkLabel={linkLabel} />
    </ThemedFlex>
  );
};